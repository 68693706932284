.profile-menu {
  width: 5.5vw;
  position: absolute;
  top: 0;
  opacity: 0.8;
  background: #f2f5f4;
  box-shadow: 1px 5px 5px 0px rgb(0 0 0 / 30%);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  z-index: 99999;
}

.profile-menu-user {
  text-align: center;
  position: relative;
  display: block;
}
a {
  text-decoration: none;
  background-color: transparent;
}

.spacer{
  width: 80%;
height: 2px;
background-color: #191919;
margin: 5px auto;
}

.user-profile {
 padding:0;
}
img {
  vertical-align: middle;
}
img {
  border-style: none;
}
.content {
  max-height: 0;
  overflow: hidden;
  background-color: #fefefe;
}

.icon-links div:not(:first-child) {
  text-align: center;
  /* margin-top: 8 px; */
  padding: 4px;
}
.profile-menu-header{
  text-align: center;
}
.icon-links img,
.icon-notifications img {
  /* width: 70%; */
  width: 80%;
  height: 80%;
  object-fit: contain;
}

.icon-links span {
  color: #333333;
  display: block;
  font-size: 9px;
  text-align: center;
  text-transform: uppercase;
}

body {
  background: #2d2d37;
}
a {
  color: white;
  text-decoration: none;
}

.arrow {
  text-align: center;
  margin: 8% 0;
}
.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.screen-reader{
  width: var(--screenReaderWidth, 1px) !important;
  height: var(--screenReaderHeight, 1px) !important;
  padding: var(--screenReaderPadding, 0) !important;
  border: var(--screenReaderBorder, none) !important;
  
  position: var(--screenReaderPosition, absolute) !important;
  clip: var(--screenReaderClip, rect(1px, 1px, 1px, 1px)) !important;
  overflow: var(--screenReaderOverflow, hidden) !important;
  }
  
  
  .popup:not(:target){
  display: none;
  }
  
  .popup:target{
  width: 100%;
  height: 100vh;
  
  display: flex;
  
  position: fixed;
  top: 0;
  right: 0;  
  }
  
  .popup::before{
  content: "";
  box-sizing: border-box;
  width: 100%;
  background-color: #0000005c;
  
  position: fixed;
  left: 0;
  top: 50%;
  }
  
  .popup::after{
  content: "";
  width: 0;
  height: 2px;
  background-color: #fff;
  
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -1px;
  }
  
  .popup__container{
  box-sizing: border-box;  
  padding: 0% 1%;
  
  height: calc(100vh - 40px); /* 1 */
  margin: auto; /* 1 */
  overflow: auto; /* 1 */
  overscroll-behavior: contain; /* 1 */
  }
  
  .popup__close{
  width: 2rem;
  height: 2rem;
  
  position: fixed;
  top: 1.5rem;
  right: 1.5rem;
  
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  background-image: url("https://www.google.com/url?sa=i&url=https%3A%2F%2Ffavpng.com%2Fpng_view%2Fwing-hand-interface-icon-basic-application-icon-close-icon-png%2FZ8amf4rN&psig=AOvVaw3NhV0lPlkwl5KLA74dytdD&ust=1638426959072000&source=images&cd=vfe&ved=0CAsQjRxqFwoTCNjt2Jv-wfQCFQAAAAAdAAAAABAE")
  }
  /*
  animation
  */
  
  .popup::before{
  will-change: height, top;
  animation: open-animation .6s cubic-bezier(0.83, 0.04, 0, 1.16) .65s both;
  }
  
  .popup::after{
  will-change: width, opacity;
  animation: line-animation .6s cubic-bezier(0.83, 0.04, 0, 1.16) both;
  }
  
  @keyframes line-animation{
  
  0%{
    width: 0;
    opacity: 1;
  }
  
  99%{
    width: 100%;
    opacity: 1;
  }
  
  100%{
    width: 100%;
    opacity: 0;
  }  
  }
  
  @keyframes open-animation{
  
  0%{
    height: 0;
    top: 50%;
  }
  
  100%{
    height: 100vh;
    top: 0;
  }
  }
  
  .popup__container{
  animation: fade .5s ease-out 1.3s both;
  }
  
  @keyframes fade{
  
  0%{
    opacity: 0;
  }
  
  100%{
    opacity: 1;
  }
  }
  
  .open-popup{
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  padding: .75rem 1.25rem;
  border: 1px solid;
  }
  
  